 <div class="links ">
    <a href="../../assets/has-docs/erisim_talep_formu.pdf" target="_blank" translate >compliance.erisimtalepform</a><br><br>
    <a href="../../assets/has-docs/tani_formu.pdf" target="_blank" translate >compliance.tani_formu</a><br><br>
    <a href="../../assets/has-docs/cercevesoz.pdf" target="_blank" translate >compliance.cervecevsozdesc</a><br><br>
    <a href="../../assets/has-docs/aydinlatma_metni.pdf" target="_blank" translate >compliance.kvkk_aydinlatma</a><br><br>
    <a href="../../assets/has-docs/ek-1-Beyan.pdf" target="_blank" translate >compliance.ekbeyan</a><br><br>
    <a href="../../assets/has-docs/masak.pdf" target="_blank" translate >compliance.masak</a><br><br>
    <a href="../../assets/has-docs/rusvetVeyolsuzluk.pdf" target="_blank" translate >compliance.rym</a><br><br>
    <a href="../../assets/has-docs/musterikabul.pdf" target="_blank" translate >compliance.msuterikabul</a><br><br>
    <a href="../../assets/has-docs/cerez.pdf" target="_blank" translate >compliance.cerez</a><br><br>


    <a href="../../assets/has-docs/hasgumus_ikibinonsekizrapor.pdf" target="_blank" translate >compliance.ikibinonsekizrapor</a><br><br>
    <a href="../../assets/has-docs/imzali_iki_bin_ondokuz.pdf" target="_blank" translate >compliance.imzali_iki_bin_ondokuz</a><br><br>
    <a href="../../assets/has-docs/HASRAPOR2020.pdf" target="_blank" translate >compliance.hasraporikibinyirmi</a><br><br>
    <a href="../../assets/has-docs/bagımsızguvencerapor.pdf" target="_blank" translate >compliance.bagımsızguvemce</a><br><br>
    
    
    
    <a href="../../assets/has-docs/uyumraporu.pdf" target="_blank" translate >compliance.uyumraporu</a><br><br>

    <a href="../../assets/has-docs/hasguvence.pdf" target="_blank" translate >compliance.guvence</a><br><br>

    <a href="../../assets/has-docs/imzali_rapor.pdf" target="_blank" translate >compliance.hasgumus2022</a><br><br>
    <a href="../../assets/has-docs/hasborsauyum.pdf" target="_blank" translate >compliance.borsa</a><br><br>

    <a href="../../assets/has-docs/hasyonetim.pdf" target="_blank" translate >compliance.yonetim</a><br><br>
    <a href="../../assets/has-docs/hasguvenuyum2023.pdf" target="_blank" translate >compliance.guvence2023</a><br><br>

    <a href="../../assets/has-docs/hasyonetim2023.pdf" target="_blank" translate >compliance.yonetim2023</a><br><br>
    <a href="../../assets/has-docs/has_gumus.pdf" target="_blank" translate >compliance.hasgumus2023</a><br><br>
</div> 
